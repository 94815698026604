
import { defineComponent, computed, onMounted, ref } from 'vue';
import MProductCarousel from 'theme/components/molecules/m-product-carousel.vue';
import OSection from 'theme/components/organisms/o-section.vue';
import MProductTags from 'theme/components/molecules/m-product-tags.vue';
import {
  getEsputnikUrl
} from 'theme/helpers';
import { useHome } from 'src/composables/pages/useHome';

export default defineComponent({
  name: 'HomePopularProducts',
  components: {
    MProductCarousel,
    OSection,
    MProductTags
  },
  props: {
    popularMainProducts: {
      type: Array,
      required: true
    },
    popularCategories: {
      type: Array,
      required: true
    },
    popularProducts: {
      type: Array,
      required: true
    }
  },
  emits: ['setActiveCategory'],
  setup (props, { emit }) {
    const {
      productPopularMainWidgetId
    } = useHome()

    const setActiveCategory = (index) => {
      emit('setActiveCategory', index)
    }
    const esUrlParamProductPopularMainWidget = ref('')

    const popularProducts = computed(() => props.popularProducts)
    const popularCategories = computed(() => props.popularCategories)
    const popularMainProducts = computed(() => props.popularMainProducts)

    onMounted(() => {
      esUrlParamProductPopularMainWidget.value = getEsputnikUrl('#' + productPopularMainWidgetId.value + ' .recommend-product-id')
    })

    return {
      setActiveCategory,
      popularMainProducts,
      popularCategories,
      popularProducts,
      esUrlParamProductPopularMainWidget
    }
  }
})
